angular.module('eOpti.services.task').service('marketingService', [
    '$rootScope', '$http', '$state', '$enum', 'taskService', function($rootScope, $http, $state,  $enum, taskService) {
        let self = this

        $enum.get('ReferenceType').then(res => {
            self.data.type = res

            self.data.type.splice(1, 1)
        })

        let resourceQuery = () => {
            if(!self.data.promotions.length) {
                $http.post('api/marketing/active', {
                    type: 1
                }).then(res => {
                    self.data.promotions = res.data

                    self.data.promotions.unshift({
                        id: 0,
                        name: 'Brak'
                    })
                }, () => {
                    $rootScope.$emit('error', 'marketing.notify.ERRORLOADDATA')
                })
            }
        }

        this.data = {
            reset() {
                self.data.promts = []
                self.data.connectionPrompts = []
                self.data.setPromts()

                resourceQuery()
            },
            promotions: [],
            type: {},
            source: {},
            getType(id) {
                let result = ''

                angular.forEach(self.data.type, type => {
                    if (type.id === id) {
                        result = type.name
                    }
                })

                return result
            },
            references: {},
            goToReference(reference) {
                let link = {
                    1: {
                        state: 'app.task.detail',
                        params: {
                            id: reference.to
                        }
                    },
                    2: {
                        state: 'app.tasks.reclamation.details',
                        params: {
                            id: reference.to
                        }
                    },
                    3: {
                        state: 'app.task.detail',
                        params: {
                            id: reference.to
                        }
                    },
                    4: {
                        state: 'app.sale.details',
                        params: {
                            id: reference.to
                        }
                    },
                    5: {
                        state: 'app.patient.detail',
                        params: {
                            id: reference.to,
                            '#': 'loyality'
                        }
                    }
                }

                if(Object.keys(link).includes(reference.type_to.toString())) {
                    $state.go(link[reference.type_to].state, link[reference.type_to].params)
                }
            },
            promts: [],
            addReference() {
                taskService.data.references.push({
                    id: 0,
                    type_from: 3,
                    from: taskService.data.id,
                    type_to: 0,
                    to: 0,
                    created_at: null,
                    updated_at: null
                })
            },
            setPromts() {
                angular.forEach(taskService.data.references, (reference, key) => {
                    self.data.promts[key] = {
                        id: reference.to,
                        name: ''
                    }
                })
            },
            removeReference(references, refToRemove) {
                angular.forEach(references, (reference, key) => {
                    if(reference.id === refToRemove.id) {
                        references.splice(key, 1)
                    }
                })
            },
            connectionPrompts: [],
            getConnectionPrompt(type, search, task_id) {
                if(parseInt(type) > 0 && search && search.length >= 3) {
                    $http.get('api/references/prompt/' + type + '/' + search + '/' + task_id).then(res => {
                        self.data.connectionPrompts = res.data
                    }, () => {
                        $rootScope.$emit('error', 'tasks.left.notify.ERRORCONNECTIONPROMPT')
                    })
                }
            }
        }
    }])